import { cloneDeep } from 'lodash';
import { MODEL_LOCK } from 'utils/constants';

export const initialLockedModelsState = {
  lockedmodels: {},
};

export function modellockReducer(state = initialLockedModelsState, action) {
  let newState;
  switch (action.type) {
    case MODEL_LOCK.ADD:
      newState = cloneDeep(state);
      const lockId = action.payload.id;
      newState.lockedmodels[lockId] = action.payload;
      return newState;
    case MODEL_LOCK.DELETE:
      newState = cloneDeep(state);
      const lockToDelete = Object.values(newState.lockedmodels).find(
        (lock) => lock.media_id === action.payload
      );
      if (lockToDelete) delete newState.lockedmodels[lockToDelete.id];
      return newState;
    case MODEL_LOCK.UPDATE:
      newState = { ...state };
      newState.lockedmodels = action.payload;
      return newState;
    default:
      return state;
  }
}
