import React from 'react';
import './brokenLinkIcon.scss';
import Icon from 'components/ui/icon';

declare interface BrokenLinkIconProps {
  link: string;
}

const BrokenLinkIcon: React.FC<BrokenLinkIconProps> = ({ link }) => {
  return (
    <div className="broken-link" data-testid="broken-link">
      <div className="broken-link-icon">
        <Icon name="unlink" title="broken link" />
      </div>
      <p className="broken-link-text">{link}</p>
    </div>
  );
};

export default BrokenLinkIcon;
