import { OrgContext } from './OrganizationContext';

export type JsonData =
  | string
  | number
  | boolean
  | null
  | { [key: string]: JsonData }
  | JsonData[];

export type ItemConfiguration = {
  key: string;
  storageLocation: Storage;
};

export class OrgStorage {
  static Items: Record<string, ItemConfiguration> = {
    acceptedCuiNotice: {
      key: 'accepted_cui_notice',
      storageLocation: localStorage,
    },
    expiredCookie: {
      key: 'expired_cookie',
      storageLocation: localStorage,
    },
    overtakeLock: {
      key: 'overtake-lock',
      storageLocation: localStorage,
    },
    redirect: {
      key: 'redirect',
      storageLocation: sessionStorage,
    },
  };

  static getItem(key: string): JsonData {
    const storageLocation = OrgStorage.getStorageLocation(key);
    const org = OrgContext.organization;
    if (!org) return null;

    const storageJson = storageLocation.getItem('orgStorage');
    if (!storageJson) return null;

    const storage = JSON.parse(storageJson);
    const orgStorage = storage[org];
    if (!orgStorage) return null;

    // to keep things in line with JsonData, we coerce undefined into null
    return orgStorage[key] || null;
  }

  static setItem(key: string, item: JsonData): void {
    const storageLocation = OrgStorage.getStorageLocation(key);
    const org = OrgContext.organization;
    if (!org) throw new Error('Cannot set OrgStorage without an OrgContext');

    const storageJson = storageLocation.getItem('orgStorage') || '{}';
    const storage = JSON.parse(storageJson);
    storage[org] = storage[org] || {};
    storage[org][key] = item;
    storageLocation.setItem('orgStorage', JSON.stringify(storage));
  }

  static removeItem(key: string): void {
    const storageLocation = OrgStorage.getStorageLocation(key);
    const org = OrgContext.organization;
    if (!org) return;

    const storageJson = storageLocation.getItem('orgStorage');
    if (!storageJson) return;

    const storage = JSON.parse(storageJson);
    const orgStorage = storage[org];
    if (!orgStorage[key]) return;

    delete orgStorage[key];
    storageLocation.setItem('orgStorage', JSON.stringify(storage));
  }

  static getStorageLocation(key: string): Storage {
    if (OrgStorage.Items[key]) {
      return OrgStorage.Items[key].storageLocation;
    }
    return localStorage;
  }
}
