import { addSeconds, parseISO } from 'date-fns';
import * as blake2 from 'blakejs';
import {
  MultiPartUploadArgs,
  MetraApiAction,
  MetraThunkAction,
  UploadPart,
  ChunkUploadRequestBody,
  UploadChunk,
  MPUploadParts,
  MPUploadCompleteResponse,
  MPUploadCompleteError,
  MultiPartUploadResponse,
  UploadFileReturnValue,
  MetraVoidAction,
  MultiPartDoUploadResponse,
  ThunkActionFunc,
  RequestError,
} from 'types';
import { AsyncTaskQueue } from 'utils/AsyncTaskQueue';
import { isFileEntry, isNone, isSome, isStandardFile } from 'helpers/utils';
import {
  MEDIA,
  NO_OP,
  apiDelete,
  apiPatch,
  apiPost,
  apiPut,
  isApiAction,
  isApiErrorAction,
} from 'modules/common';
import { getTenantState } from 'modules/auth/auth';
import { tickUploadingCount } from 'modules/library/other';
import { MESSAGES } from 'modules/ui/messages';
import { MAX_CONCURRENT_CONNECTIONS, UPLOAD_CHUNK_SIZE } from './constants';
import { ACTIVITY_PING_SECONDS } from 'utils/settings';
import { isNumber } from 'helpers/utils';
import type { uploadFile as uploadFileFunc } from './actions';
import { t3dev } from 't3dev';
import { ApiError } from 'redux-api-middleware';
import { makeFetcher } from 'helpers/api';

const [enqueueTask, processQueue] = AsyncTaskQueue<
  Option<UploadFileReturnValue>
>(MAX_CONCURRENT_CONNECTIONS);

export declare type ETagContainer = {
  etag: Option<string>;
};

const logger = t3dev().logger('TaskQueue');

export class MultiPartUpload {
  bytesUploadedByChunkNumber: Record<number, number> = {};
  uploadingFile: File;
  projectId: Numberish;
  guildCName: Option<string>;
  belongsTo: Option<Numberish>;
  overwriteExisting: boolean;
  deleteInvalid: boolean;
  signal: AbortSignal;
  onProgress: (event: ProgressEvent) => void;
  onUploaded: (event: Event) => void;
  activityPingSeconds: number;
  uploadId = 0;
  uploadFile: typeof uploadFileFunc;

  constructor(args: MultiPartUploadArgs, uploadFile: typeof uploadFileFunc) {
    this.uploadFile = uploadFile;
    if (!args.file) throw new Error('no-file-provided');
    if (isStandardFile(args.file)) {
      this.uploadingFile = args.file;
    } else if (isFileEntry(args.file)) {
      this.uploadingFile = args.file.file;
    } else {
      throw new Error('cannot upload a folder');
    }
    this.projectId = args.projectId;
    this.guildCName = args.guildCName;
    this.belongsTo = args.belongsTo;
    this.overwriteExisting = args.overwriteExisting;
    this.deleteInvalid = args.deleteInvalid;
    this.signal = args.signal;
    this.onProgress = args.onProgress;
    this.onUploaded = args.onUploaded;
    this.activityPingSeconds =
      args.activityPingSeconds ?? ACTIVITY_PING_SECONDS;
  }

  doUpload<P1, P2>(): MetraThunkAction<
    P1,
    P2,
    Promise<MultiPartDoUploadResponse>
  > {
    return async (dispatch) => {
      const pingIntervalId = window.setInterval(
        () => dispatch(getTenantState()),
        this.activityPingSeconds * 1000
      );
      dispatch(tickUploadingCount(1));

      try {
        const postResponse = await dispatch(this.initialize());
        if (isApiErrorAction(postResponse)) {
          return dispatch({
            ...postResponse,
            type: MEDIA.CREATE_FAILURE,
          });
        }
        const responseContent = postResponse.payload;

        if (this.signal && this.signal.aborted) {
          return await dispatch(this.handleAbort());
        }

        this.uploadId = responseContent.id;
        let chunkError!: MetraVoidAction | MetraApiAction<MPUploadParts, void>;
        this.reportPreviousProgress(responseContent.parts);
        const uploadPromises = responseContent.parts.map((part) =>
          dispatch(this.prepareChunkUpload(part))
        );
        const enqueueResults = await Promise.allSettled(uploadPromises);

        const uploadChunkResults = await processQueue();
        const hasError = uploadChunkResults.some((result) => {
          // null means success, so if result is not null we have an error
          // and since we only returned the first error, we return true
          // on first error, short circuiting `some`
          if (isSome(result)) {
            chunkError = result;
            return true;
          }
          return false;
        });
        logger.log('all the results::', {
          enqueueResults,
          uploadChunkResults,
          hasError,
        });
        if (hasError) {
          return chunkError;
        }

        if (this.signal && this.signal.aborted) {
          return await dispatch(this.handleAbort());
        }

        const assemblyResult = await dispatch(
          this.assembleChunks(responseContent)
        );
        if (isApiErrorAction(assemblyResult)) {
          return assemblyResult;
        }
        this.onUploaded?.(new Event('load'));
        return assemblyResult;
      } catch (e) {
        return dispatch({
          type: MEDIA.CREATE_FAILURE,
          payload: 'exception-encountered',
        });
      } finally {
        clearInterval(pingIntervalId);
        dispatch(tickUploadingCount(-1));
      }
    };
  }

  initialize: ThunkActionFunc<
    [],
    Promise<MetraApiAction<MultiPartUploadResponse>>
  > = () => {
    return async (dispatch) => {
      // This registers a multi-part upload with the back end.  The back end
      // assigns a target-url for each chunk, along with some per-chunk tracking
      // info.
      const requestBody: ChunkUploadRequestBody = {
        chunk_size: UPLOAD_CHUNK_SIZE,
        file_size: this.uploadingFile.size,
        name: this.uploadingFile.name,
        overwrite: this.overwriteExisting,
        project: isNumber(this.projectId)
          ? `${this.projectId}`
          : this.projectId,
        unique_key: await this.computeHash(this.uploadingFile),
        delete_invalid: this.deleteInvalid,
      };

      if (isSome(this.belongsTo)) {
        requestBody.belongs_to = isNumber(this.belongsTo)
          ? `${this.belongsTo}`
          : this.belongsTo;
      }

      const postResponse = await dispatch(
        apiPost<MultiPartUploadResponse>({
          entity: 'uploads-mp/file',
          body: JSON.stringify(requestBody),
          headers: {
            'Content-Type': 'application/json',
          },
          guildCName: this.guildCName,
          types: [NO_OP.SUCCESS, MEDIA.UPLOAD_FAILURE],
          error: MESSAGES.ERROR.UPDATE.NETWORK,
        })
      );

      return postResponse;
    };
  };

  prepareChunkUpload: ThunkActionFunc<
    [part: UploadPart],
    Promise<Option<UploadFileReturnValue>>
  > = (part) => {
    return async (dispatch) => {
      const { id, part_number, url, upload } = part;

      const chunkStart = (part_number - 1) * UPLOAD_CHUNK_SIZE;
      const chunkEnd = Math.min(
        chunkStart + UPLOAD_CHUNK_SIZE,
        this.uploadingFile.size
      );
      const content = this.uploadingFile.slice(chunkStart, chunkEnd);
      const chunk: UploadChunk = {
        content,
        id,
        part_number,
        url,
        upload,
      };

      if (this.signal && this.signal.aborted) {
        return dispatch({ type: MEDIA.CREATE_CANCELLED });
      }

      enqueueTask(() => dispatch(this.buildUploadChunkTask(id, chunk)));
    };
  };

  buildUploadChunkTask: ThunkActionFunc<
    [partId: number, chunk: UploadChunk],
    Promise<Option<UploadFileReturnValue>>
  > = (partId: number, chunk: UploadChunk) => {
    return async (dispatch) => {
      // the user might have cancelled before this task ever started
      if (this.signal && this.signal.aborted) {
        return dispatch({ type: MEDIA.CREATE_CANCELLED });
      }

      // chunk.url has a short lifespan before it goes stale and will not work.
      // Make sure the URL is either fresh, or go get a refreshed version.
      // Don't try to do all freshness work for all the chunks, then try to
      // upload all the chunks - the uploads can easily take longer than the
      // freshness-lifespan.
      // (like, don't bake a million sandwiches worth of bread, then try to make
      // a million sandwiches - the bread will go stale before you finish).
      const freshnessResult = await dispatch(this.ensureUrlFreshness(chunk));
      if (!freshnessResult.success) {
        return dispatch({
          type: MEDIA.CREATE_FAILURE,
          payload: 'failed-upload-parts-check',
        });
      }

      if (this.signal && this.signal.aborted) {
        return dispatch({ type: MEDIA.CREATE_CANCELLED });
      }

      const response = await dispatch(
        this.uploadChunk({
          ...chunk,
          url: freshnessResult.targetUrl,
        })
      );
      if (isNone(response)) {
        return response;
      }

      if (isApiAction(response)) {
        // error in api call
        if (isApiErrorAction(response)) {
          return response;
        }

        // etag field does not exist
        if (!('etag' in (response as MetraApiAction<ETagContainer>).payload)) {
          return dispatch({ type: MEDIA.CREATE_FAILURE, payload: 'no-etag' });
        }
        const etag = (response.payload as ETagContainer).etag;
        // etag field exists but is undefined
        if (isNone(etag)) {
          return dispatch({ type: MEDIA.CREATE_FAILURE, payload: 'no-etag' });
        }

        if (this.signal && this.signal.aborted) {
          return dispatch({ type: MEDIA.CREATE_CANCELLED });
        }

        // the chunk has been uploaded, but we make another call to let nucleo
        // know it's in place and ready to be assembled as part of the file.  It
        // isn't *critical* to do this right away; but doing this 'locks in' the
        // partial upload as recoverable in case the page crashes, so let's do it
        // now instead of breaking it off to another asynchronous queue
        return dispatch(this.associateChunk(partId, etag));
      } else {
        return response;
      }
    };
  };

  ensureUrlFreshness: ThunkActionFunc<
    [chunk: UploadChunk],
    Promise<{ targetUrl: string; success: boolean }>
  > = (chunk: UploadChunk) => {
    return async (dispatch) => {
      let targetUrl = chunk.url;
      let success = false;
      // The URL might have expired ... it also might have never been generated
      // (the backend only pre-generates a few to speed things up at the start).
      // In either case [re-]generate the targetUrl.
      if (
        targetUrl &&
        targetUrl !== '' &&
        !this.isTargetUrlExpired(targetUrl)
      ) {
        success = true;
      } else {
        const refreshResult = await dispatch(
          apiPatch<UploadPart>({
            entity: `upload-parts/${chunk.id}`,
            body: JSON.stringify({ update_url: true }),
            guildCName: this.guildCName,
            headers: {
              'Content-Type': 'application/json',
            },
            types: [NO_OP.SUCCESS, NO_OP.FAILURE],
            error: MESSAGES.ERROR.UPDATE.NETWORK,
          })
        );

        if (!isApiErrorAction(refreshResult)) {
          success = true;
          targetUrl = refreshResult.payload.url;
        }
      }
      return { targetUrl, success };
    };
  };

  uploadChunk: ThunkActionFunc<
    [chunk: UploadChunk],
    Promise<
      | MetraApiAction<ETagContainer>
      | MetraVoidAction
      | { type: 'media/CREATE_CANCELLED' }
    >
  > = (chunk: UploadChunk) => {
    return async (dispatch) => {
      let aborted = false;

      if (this.signal && this.signal.aborted) {
        return await dispatch(this.handleAbort());
      }

      const putAction = apiPut<{ etag: Option<string>; status: number }>({
        explicit: chunk.url,
        body: chunk.content,
        guildCName: this.guildCName,
        headers: {
          'Access-Control-Expose-Headers': 'ETag,Etag,eTag,etag',
          'Content-Type': 'multipart/byteranges',
        },
        types: [
          {
            type: MEDIA.UPLOAD_SUCCESS,
            payload: async (_a, _s, response) => {
              let etag =
                response.headers.get('ETag') || response.headers.get('etag');
              if (!etag || etag === 'null') {
                etag = null;
              }
              return { etag, status: response.status };
            },
          },
          MEDIA.UPLOAD_FAILURE,
        ],
        forceDisableAuth: true,
        fetcher: makeFetcher({
          signal: this.signal,
          onAbort: () => {
            aborted = true;
          },
          onProgress: (e) => this.onChunkProgress(chunk.part_number, e),
        }),
      });

      let response = await dispatch(putAction);
      if (response.payload.status >= 400) {
        t3dev().log.warn(
          'File chunk upload failed with status',
          response.payload.status,
          'retrying...'
        );
        response = await dispatch(putAction);
      }

      if (aborted) {
        return { type: MEDIA.UPLOAD_ABORT };
      }
      return response;
    };
  };

  isTargetUrlExpired(targetUrl: string) {
    const urlParams = new URL(targetUrl).searchParams;
    const creationDate = parseISO(urlParams.get('X-Amz-Date') || '');
    const expiresInSecs = Number(urlParams.get('X-Amz-Expires'));
    const expiryDate = addSeconds(creationDate, expiresInSecs);
    return expiryDate < new Date();
  }

  associateChunk: ThunkActionFunc<
    [chunkId: number, eTag: string],
    Promise<{
      type: 'media/CREATE_FAILURE';
      payload: RequestError | ApiError<MPUploadParts>;
    } | void>
  > = (chunkId: number, eTag: string) => {
    return async (dispatch) => {
      // This lets the backend know we've uploaded a particular chunk and that
      // chunk can be used during re-assembly of the file
      const associateResponse = await dispatch(
        apiPatch<MPUploadParts>({
          entity: `upload-parts/${chunkId}`,
          body: JSON.stringify({
            etag: eTag,
            update_url: false,
          }),
          guildCName: this.guildCName,
          headers: {
            'Content-Type': 'application/json',
          },
          types: [MEDIA.UPLOAD_SUCCESS, MEDIA.UPLOAD_FAILURE],
          error: MESSAGES.ERROR.UPDATE.NETWORK,
        })
      );
      if (isApiErrorAction(associateResponse)) {
        return dispatch({
          type: MEDIA.CREATE_FAILURE,
          payload: associateResponse.payload,
        });
      }
    };
  };

  assembleChunks: ThunkActionFunc<
    [uploadResponse: MultiPartUploadResponse],
    Promise<MetraApiAction<MPUploadCompleteResponse, MPUploadCompleteError>>
  > = (uploadResponse) => {
    return async (dispatch) => {
      // This lets the backend know we've uploaded all the chunks, and it should
      // reassemble the file now.
      const postResponse = await dispatch(
        apiPost<MPUploadCompleteResponse, MPUploadCompleteError>({
          entity: `uploads-mp/${uploadResponse.id}/_complete`,
          headers: {
            'Content-Type': 'application/json',
          },
          guildCName: this.guildCName,
          types: [MEDIA.CREATE_SUCCESS, MEDIA.CREATE_FAILURE],
          error: MESSAGES.ERROR.UPDATE.NETWORK,
        })
      );
      return postResponse;
    };
  };

  onChunkProgress(id: number, event: ProgressEvent) {
    this.bytesUploadedByChunkNumber[id] = event.loaded;
    const totalBytesUploaded = Object.reduce(
      this.bytesUploadedByChunkNumber,
      (accumulator, [_id, currentValue]) => accumulator + currentValue,
      0
    );
    this.onProgress(
      new ProgressEvent('progress', {
        loaded: totalBytesUploaded,
      })
    );
  }

  async computeHash(file: File) {
    // We're looking for a value that will change if the file changes, and won't
    // change if the file doesn't change.  This doesn't need to be perfect, and
    // may have false negatives / positives.  The goal is to help identify if we
    // are re-starting a previously-failed chunked upload (vs. uploading a new
    // version of the same file).
    // We're going to use a blake2b hash of the last 8kb of the file.
    const hashedContentStart = Math.max(0, file.size - 8096);
    const hashedContentEnd = file.size;
    const hashedContent = file.slice(hashedContentStart, hashedContentEnd);
    const data = new Uint8Array(await hashedContent.arrayBuffer());
    const hashValue = blake2.blake2bHex(data);
    return hashValue;
  }

  handleAbort(): MetraThunkAction<void, void, Promise<MetraVoidAction>> {
    return async (dispatch) => {
      // if the user aborted the upload we delete any partial-progress
      // We don't need to await this dispatch, we're not going to do
      // anything with the response, even if it errors out
      dispatch(
        apiDelete({
          entity: `uploads-mp/${this.uploadId}`,
          headers: {
            'Content-Type': 'application/json',
          },
          guildCName: this.guildCName,
          types: [NO_OP.SUCCESS, NO_OP.FAILURE],
        })
      );
      return { type: MEDIA.CREATE_CANCELLED };
    };
  }

  reportPreviousProgress(pendingChunkTargets: UploadPart[]) {
    // we may be re-starting an upload that was partially-accomplished
    // previously.  If so we want to report on the bytes already uploaded in the
    // previous attempt.
    const fullChunkCount = Math.ceil(
      this.uploadingFile.size / UPLOAD_CHUNK_SIZE
    );
    // upload parts are not 0-indexed
    for (let i = 1; i < fullChunkCount + 1; i++) {
      if (
        !pendingChunkTargets.some(
          (chunkTarget) => chunkTarget.part_number === i
        )
      ) {
        // this chunk didn't show up as still-needed, so it must have been
        // previously uploaded.
        const chunkSize =
          i < fullChunkCount
            ? UPLOAD_CHUNK_SIZE
            : this.uploadingFile.size -
              (fullChunkCount - 1) * UPLOAD_CHUNK_SIZE;
        const previousProgressEvent = new ProgressEvent('progress', {
          loaded: chunkSize,
        });
        this.onChunkProgress(i, previousProgressEvent);
      }
    }
  }
}
