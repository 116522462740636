import { UrlFor, UrlForExplicit } from 'utils/urlFor';
import { getCurrentScope } from '@sentry/browser';
import Cookies from 'js-cookie';
import { combineReducers } from 'redux';
import {
  SESSION_TOKEN,
  AUTH_AUTHORIZED,
  AUTH_LOGOUT,
  AUTH_EMAIL_SUCCESS,
  AUTH_PKI_SUCCESS,
  AUTH_FAILURE_CREDS,
  AUTH_FAILURE_PKI,
  AUTH_FAILURE_MFA,
  AUTH_TENANT_STATUS,
  AUTH_TENANT_SETTINGS,
  MFA_VERIFY_SUCCESS,
  MFA_VERIFY_FAILURE,
  AUTH_FORCING_LOGOUT,
  NO_OP,
  apiGet,
  apiPost,
  genericApiReducer,
  genericValueReducer,
  isApiAction,
  isApiErrorAction,
  isApiResponseAction,
} from 'modules/common';
import {
  buildOrgUrl,
  buildGuildUrl,
  buildContextUrl,
} from 'utils/url-builders';
import { OrgStorage } from 'utils/orgStorage';
import {
  AuthReducer,
  MetraSimpleAction,
  MetraActionFunc,
  MetraApiAction,
  MetraApiReducer,
  TenantResponse,
  TenantState,
  ThunkActionFunc,
} from 'types';
import { logError } from 'utils/utils-extra';

/*
  UTILS
*/

export const determineAuthState = (): boolean => {
  //if we have an active SESSION_TOKEN, we're logged in
  return !!Cookies.get(SESSION_TOKEN);
};

export const determineMfaVerified = (): boolean => {
  return !!Cookies.get('mfaVerified');
};

/**
 * retrieve a timeout-related cookie and check whether it means the user timed-out
 * @param  timeoutCookie
 */
export const isSessionTimedOut = (timeoutCookie: string): boolean => {
  return (
    Date.now() / 1000 >= Number.parseInt(Cookies.get(timeoutCookie) ?? '0')
  );
};

export const clearSessionCookies = () => {
  Cookies.remove('session_max_age');
  Cookies.remove('session_idle_time');
};

/*
  ACTIONS
*/

export const setAuthorized: MetraActionFunc<[boolean], boolean> = (
  authorized
) => ({
  type: AUTH_AUTHORIZED,
  payload: authorized,
});

//
// NOTE: not used anymore
//
// export const initAuth = (credentials) => {
//   const data = new FormData();
//   data.append('email', credentials.email.value);
//   data.append('password', credentials.password.value);
//   return apiPost({
//     entity: 'auth/login/',
//     body: data,
//     types: [AUTH_EMAIL_SUCCESS, AUTH_FAILURE_CREDS],
//   });
// };

//
// NOTE: not used anymore
//
// export const pkiAuth = () => {
//   const data = new FormData();
//   return apiPost({
//     entity: 'auth/caclogin/',
//     body: data,
//     types: [AUTH_PKI_SUCCESS, AUTH_FAILURE_PKI],
//   });
// };

/**
 * initiates logout and optionally accept a location override to '/'
 * @param [rememberLocation] - when true, the user will return to their previous page on login
 * when false, the user will return to the home page on login
 * @returns a redux thunk
 */
export const logoutAuth: ThunkActionFunc<[rememberLocation?: boolean], void> = (
  rememberLocation = false
) => {
  return async (_dispatch, getState) => {
    const route = rememberLocation
      ? window.location.pathname
      : buildContextUrl();
    OrgStorage.setItem(OrgStorage.Items.redirect.key, route);
    const redirectUri = encodeURIComponent(
      `${window.location.origin + buildContextUrl()}/log/me/out`
    );
    const state = getState();
    const userId = state.userReducer.currentUser;
    const user = state.entityReducer.users[userId];
    const logoutUrl = `${
      getState().configReducer.logoutUrl
    }?post_logout_redirect_uri=${redirectUri}&id_token_hint=${user.id_token}`;

    setTimeout(() => {
      window.location.replace(logoutUrl);
    }, 0);
  };
};

/**
 * performs post logout
 * @returns
 */
export const postLogout: ThunkActionFunc<
  [location: string],
  Promise<MetraApiAction<{ detail: string }>>
> = (location) => async (dispatch) => {
  return await dispatch(
    apiPost<{ detail: string }>({
      entity: 'auth/logout/',
      types: [AUTH_LOGOUT, AUTH_LOGOUT],
      meta: {
        excludeGuild: true,
        enableReplace: true,
        location,
      },
    })
  );
};

export const verifyMFA: ThunkActionFunc<
  [token: string],
  Promise<MetraApiAction<unknown, void>>
> = (token) => {
  return async (dispatch, getState) => {
    const { entityReducer, userReducer } = getState();
    const user = entityReducer.users[userReducer.currentUser];
    return await dispatch(
      apiPost({
        entity: `users/${user.id}/totp/_verify`,
        body: JSON.stringify({ token }),
        headers: { 'Content-Type': 'application/json' },
        types: [MFA_VERIFY_SUCCESS, MFA_VERIFY_FAILURE],
      })
    );
  };
};

/**
 *
 * @returns
 */
export const getTenantState: ThunkActionFunc<[], Promise<TenantState>> = () => {
  return async (dispatch) => {
    const cached = localStorage.getItem('tenantExists');
    if (cached)
      dispatch({
        type: AUTH_TENANT_STATUS,
        payload: cached === 'true',
      });
    const route = UrlForExplicit(`/api${buildOrgUrl()}/hello`);
    let responseJson = {} as TenantResponse; // = { settings: {} };
    let tenantExists = false;
    try {
      const response = await dispatch(
        apiGet<TenantResponse>({
          explicit: route,
          types: [
            {
              type: NO_OP.SUCCESS,
              payload: async (_action, _state, response) => {
                return await response.json();
              },
            },
            {
              type: NO_OP.FAILURE,
              payload: async (_action, _state, response) => {
                return await response.json(); // as any;
              },
            },
          ],
        })
      );
      if (isApiResponseAction(response)) {
        responseJson = response.payload;
        tenantExists = true;
      }
    } catch (error) {
      logError(error, 'GET TENANT STATE ERROR:');
      // leave tenantExists as false, responseJson.settings as undefined
      throw error;
    }
    const { settings } = responseJson;
    return {
      tenantExists,
      settings,
    };
  };
};

export const finalizeTenant: ThunkActionFunc<
  [TenantState],
  MetraSimpleAction<TenantState['settings']>
> =
  ({ tenantExists, settings }) =>
  (dispatch) => {
    localStorage.setItem('tenantExists', `${tenantExists}`);
    dispatch({
      type: AUTH_TENANT_STATUS,
      payload: tenantExists,
    });
    return dispatch({
      type: AUTH_TENANT_SETTINGS,
      payload: settings,
    });
  };

export const registerCAC = async (token: string): Promise<any> => {
  const url = UrlFor(`${buildGuildUrl()}/auth/caclogin`, token);
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'X-CSRFToken': Cookies.get('csrftoken') ?? 'undefined',
    },
  });
  let json = await response.json();

  return {
    ...json,
    error: !response.ok,
  };
};

/*
  REDUCERS
 */

export const handleSetAuth: MetraApiReducer<boolean> = (state, action) => {
  return 'payload' in action && !('error' in action) ? action.payload : state;
};

export const handleAuthSuccess: MetraApiReducer<boolean> = (_state, action) => {
  if (isApiErrorAction(action)) {
    return false;
  } else if (isApiAction(action)) {
    // is a response action
    Cookies.set(SESSION_TOKEN, action.payload.key, {
      expires: 1,
      secure: process.env.environment === 'production' ? true : false,
      domain: process.env.PUBLIC_URL,
    });
    return true;
  } else {
    return false;
  }
};

/** when the session ends, cleans up sentry, removes cookies, and
 * 'enableReplace' indicates the url should redirect home
 *
 * Always returns true to avoid a double-load, see #1393
 */
export const handleSessionEnd: MetraApiReducer<boolean> = (_state, action) => {
  getCurrentScope().setUser(null);
  [
    'csrftoken',
    SESSION_TOKEN,
    'mfaVerified',
    'session_max_age',
    'session_idle_time',
  ].forEach((item) => Cookies.remove(item));
  // clear the clipboard
  localStorage.removeItem('clipboard');
  // optionally replace the location based on set flag
  if (isApiAction(action) && action?.meta?.enableReplace) {
    const location = action?.meta?.location
      ? action.meta.location
      : buildContextUrl();
    window.location.assign(location);
  } else {
    window.location.reload();
  }
  return false;
};

export const handleAuthFailure = () => {
  getCurrentScope().setUser(null);
  Cookies.remove(SESSION_TOKEN);
  return false;
};

export const handleMfaSuccess = () => {
  Cookies.set('mfaVerified', `${true}`);
  return true;
};

export const handleMfaFailure = () => {
  Cookies.remove('mfaVerified');
  return false;
};

/**
 *
 */
export const initialState: AuthReducer = {
  authenticated: false,
  tenantExists: null,
  tenantSettings: null,
  pkiAuthError: false,
  credentialAuthError: false,
  forcingLogout: false,
  mfaVerified: determineMfaVerified(),
};

const authenticated = genericApiReducer(
  {
    [AUTH_AUTHORIZED]: handleSetAuth,
    [AUTH_EMAIL_SUCCESS]: handleAuthSuccess,
    [AUTH_PKI_SUCCESS]: handleAuthSuccess,
    [AUTH_LOGOUT]: handleSessionEnd,
    [AUTH_FAILURE_PKI]: handleAuthFailure,
    [AUTH_FAILURE_CREDS]: handleAuthFailure,
  },
  // NOTE: this should NEVER be true unless we actually are authenticated
  // the old cookie check method is no longer reliable
  false
);

const mfaVerified = genericApiReducer(
  {
    [MFA_VERIFY_SUCCESS]: handleMfaSuccess,
    [MFA_VERIFY_FAILURE]: handleMfaFailure,
    [AUTH_PKI_SUCCESS]: handleMfaSuccess,
    [AUTH_FAILURE_MFA]: () => false,
    [AUTH_LOGOUT]: () => false,
  },
  determineMfaVerified()
);

const pkiAuthError = genericApiReducer(
  {
    [AUTH_LOGOUT]: () => false,
    [AUTH_EMAIL_SUCCESS]: () => false,
    [AUTH_PKI_SUCCESS]: () => false,
    [AUTH_FAILURE_PKI]: () => true,
  },
  false
);

const credentialAuthError = genericApiReducer(
  {
    [AUTH_LOGOUT]: () => false,
    [AUTH_EMAIL_SUCCESS]: () => false,
    [AUTH_PKI_SUCCESS]: () => false,
    [AUTH_FAILURE_CREDS]: () => true,
  },
  false
);

const forcingLogout = genericValueReducer<boolean>(AUTH_FORCING_LOGOUT, false);
const tenantExists = genericValueReducer<Option<boolean>>(
  AUTH_TENANT_STATUS,
  null
);
const tenantSettings = genericValueReducer<Option<Record<string, unknown>>>(
  AUTH_TENANT_SETTINGS,
  null
);

export const authReducer = combineReducers<AuthReducer>({
  authenticated,
  pkiAuthError,
  credentialAuthError,
  mfaVerified,
  forcingLogout,
  tenantExists,
  tenantSettings,
});
