import { Reducer } from 'redux';
import {
  API_CORE_REQUEST,
  API_CORE_FAILURE,
  ENTITIES,
  PAGINATION,
  isApiResponseAction,
  apiGet,
  isApiAction,
  isSimpleAction,
  API_CORE_SUCCESS,
  UPDATE_RECENT_TAGS,
} from 'modules/common';
import { MESSAGES } from 'modules/ui/messages';
import { getMediaFilesize } from 'modules/media/media-extra';
import {
  ApiRequest,
  BaseCollectionReducer,
  CollectionKey,
  CollectionReducer,
  EntityKey,
  EntityTypes,
  MetraActionFunc,
  MetraApiAction,
  MetraCollection,
  NormalizedResult,
  Paginated,
  MetraAction,
  MetraNullCollection,
  ThunkAction,
  AsImmutableRecord,
} from 'types';
import { isActually, isNone } from 'helpers/utils';
import { Immutable } from 'seamless-immutable';

export function createEmptyCollection(schema: EntityKey): MetraCollection {
  return {
    schema,
    count: -1,
    next: null,
    loading: false,
    ids: [],
  };
}

/**
 * Given a state and a collection, updates the collection with
 * data from the server payload
 */
function addResultsToCollection<T extends EntityKey>(
  state: CollectionReducer,
  collection: CollectionKey,
  payload: NormalizedResult<T>
): CollectionReducer {
  const maybeCollection = collection
    ? state[collection]
    : ({} as Partial<MetraCollection | MetraNullCollection>);
  const baseIdList: Numberish[] =
    payload.previous && maybeCollection?.ids ? maybeCollection.ids : [];

  return mergeWithCollection(state, collection, {
    count: payload.count,
    next: payload.next,
    loading: false,
    ids: [...baseIdList, ...payload.results],
  });
}

/**
 * Given a state, the ID of a record to remove, and the name of the schema
 * holding that record, returns a new state where no collection of that schema
 * type contains that ID
 */
function deleteFromAllCollections(
  state: BaseCollectionReducer,
  targetSchema: EntityKey,
  targetId: Numberish
): CollectionReducer {
  const newState: CollectionReducer = {};
  // For each collection
  Object.forEach(state, ([key, value]) => {
    // if the collection is of the same schema
    if (value.schema === targetSchema) {
      // Update collection ID list and count
      newState[key] = {
        ...value,
        count: value.count - 1,
        ids: value.ids.filter((id) => id !== targetId),
      };
    } else {
      // If the collection is for a different type of entity, make no change
      newState[key] = value;
    }
  });
  return newState;
}

/**
 * Helper function, lets the user overlay a new state on a collection
 */
function mergeWithCollection(
  state: CollectionReducer,
  collection: CollectionKey,
  overlay: Partial<MetraCollection | MetraNullCollection>
): CollectionReducer {
  if (overlay.schema === '_null' && collection) {
    return {
      ...state,
      [collection]: {
        ...state[collection],
        ...overlay,
        schema: state[collection]?.schema,
      },
    };
  } else if (collection) {
    return {
      ...state,
      [collection]: {
        ...state[collection],
        ...overlay,
      },
    };
  } else {
    return state;
  }
}

/**
 * Action to clear a collection
 * @param collection - the name of the collection to be cleared
 * @returns an action
 */
export const clearCollection: MetraActionFunc<
  [collection: CollectionKey],
  CollectionKey
> = (collection) => ({
  type: PAGINATION.CLEAR_RESULTS,
  payload: collection,
});

export function loadMore<Keys extends EntityKey, ErrorType = void>(
  collectionName: keyof CollectionReducer
): ThunkAction<
  Promise<Option<MetraApiAction<NormalizedResult<Keys>, ErrorType>>>
> {
  return async (dispatch, getState) => {
    const {
      activityReducer,
      collectionReducer,
      libraryReducer,
      archiveReducer,
      searchReducer,
      recentsReducer,
    } = getState();
    const limitedCollections: Partial<AsRecord<Paginated>> = {
      activity_logs: activityReducer,
      mediaArchives: archiveReducer,
      mediaSearchResults: searchReducer,
      projectLibrary: libraryReducer,
      recentModels: recentsReducer.models,
      recentFiles: recentsReducer.files,
    };
    const reducer = limitedCollections?.[collectionName] ?? null;
    const collection = collectionReducer?.[collectionName];
    if (isNone(collection)) return;
    const { next, schema } = collection;
    if (isNone(next)) return;
    const url = new URL(next);
    if (reducer) {
      const limit = reducer.pagination.numberOfRows;
      const params = new URLSearchParams(url.search);
      params.delete('limit');
      params.set('limit', limit.toString());
      url.search = params.toString();
    }

    const results = await dispatch(
      apiGet<NormalizedResult<Keys>, ErrorType>({
        explicit: url.toString(),
        meta: { schema },
        collection: collectionName,
        types: [ENTITIES.ACTION_SUCCESS, API_CORE_FAILURE],
        error: MESSAGES.ERROR.GET.COLLECTION,
      })
    );

    // Loaded media is missing file_size; get it with async call
    if (isApiResponseAction(results)) {
      const media = results.payload?.entities?.media ?? {};
      const returnedIds = Object.keys(media);
      if (returnedIds.length > 0) {
        dispatch(getMediaFilesize({ ids: returnedIds }));
      }
    }
    return results;
  };
}

const _emptyArray: any[] = [];
/**
 * @param collection - a collection to map
 * @param entities - current entity reducer state
 * @returns a mapped collection
 */
export function mapCollectionToEntities<
  E extends EntityTypes,
  T extends Immutable<E>
>(collection?: Option<IIndexable>, entities?: AsImmutableRecord<E>): T[] {
  if (isNone(collection) || !collection.ids || !collection.ids.length)
    return _emptyArray;
  if (isNone(entities)) return _emptyArray;
  const ret = collection.ids.reduce((arr, id) => {
    if (id in entities) {
      return arr.addItem(isActually<T>(entities[id]));
    } else {
      return arr;
    }
  }, [] as T[]);
  return ret;
}

export const initialCollectionState: CollectionReducer = {};

export const collectionReducer: Reducer<
  CollectionReducer,
  MetraAction<
    | MetraCollection
    | CollectionKey
    | ApiRequest<any, any>
    | {
        loading: false;
        collection: 'orgUsers';
      },
    NormalizedResult<EntityKey>,
    any
  >
> = (state = initialCollectionState, action) => {
  if (action.type === UPDATE_RECENT_TAGS) {
    const { payload } = action as unknown as { payload: Numberish[] };

    return mergeWithCollection(state, 'recentTags', {
      count: payload.length,
      ids: payload,
    });
  }

  if (isApiAction(action)) {
    const { collection, schema, mutation } = action.meta;

    // make a new state copy
    const newState = { ...state };

    // if this collection does not exist already, create it
    if (collection && isNone(newState[collection]) && schema) {
      newState[collection] = createEmptyCollection(schema);
    }

    if (isApiResponseAction(action)) {
      if (action.type === ENTITIES.ACTION_SUCCESS) {
        if (
          mutation === ENTITIES.MUTATE_DELETE &&
          schema &&
          action.meta.record
        ) {
          return deleteFromAllCollections(
            newState as BaseCollectionReducer,
            schema,
            action.meta.record
          );
        }

        if (collection && mutation === ENTITIES.MUTATE_UPDATE_MANY) {
          const maybeCollection = newState?.[collection];

          if (maybeCollection) {
            return mergeWithCollection(newState, collection, {
              count: action.payload.count,
              ids: [...action.payload.results],
            });
          }

          return state;
        }

        if (mutation === ENTITIES.MUTATE_CREATE && schema === 'tags') {
          // update recentTags Collection when a new tag is created in the recents view
          const recentTagsIds = newState.recentTags?.ids ?? [];
          const payload = action.payload as any;
          const ids = [...new Set([...recentTagsIds, payload.id])];

          return mergeWithCollection(newState, 'recentTags', {
            count: ids.length,
            ids,
          });
        }

        if (
          mutation === ENTITIES.MUTATE_CREATE &&
          collection &&
          'id' in action.payload
        ) {
          const maybeCollection = newState?.[collection] ?? null;
          if (isNone(maybeCollection)) return state;
          return mergeWithCollection(newState, collection, {
            count: Math.max(maybeCollection.count + 1, 1),
            ids: [...maybeCollection.ids, action.payload.id as Numberish],
          });
        }

        if (mutation === ENTITIES.MUTATE_READ && collection) {
          return addResultsToCollection(newState, collection, action.payload);
        }
      }
    }

    return state;
  } else if (isSimpleAction(action)) {
    if (typeof action.payload === 'string') {
      // if there is no pre-existing collection, just return state
      if (!state[action.payload]) return state;
      if (action.type === PAGINATION.CLEAR_RESULTS) {
        return mergeWithCollection(state, action.payload, {
          schema: '_null',
          count: -1,
          next: null,
          loading: false,
          ids: [],
        });
      }

      return state;
    } else {
      if (
        action.type === API_CORE_REQUEST &&
        action.payload &&
        'collection' in action.payload &&
        action.payload.collection
      ) {
        const newState = Object.clone(state);
        const collection = action.payload.collection;

        if (isNone(newState[collection])) {
          return state;
        }

        // if this is the first API_CORE_REQUEST,
        // collection probably doesn't exist yet
        if ('entity' in action.payload && action.payload.entity) {
          const schema = action.payload.entity as EntityKey;

          if (isNone(newState[collection]) && schema) {
            newState[collection] = createEmptyCollection(schema);
          }
        }

        return mergeWithCollection(newState, collection, {
          loading: true,
        });
      }

      if (
        action.type === API_CORE_SUCCESS &&
        action.payload &&
        'collection' in action.payload &&
        action.payload.collection
      ) {
        const newState = Object.clone(state);
        const collection = action.payload.collection;

        if (isNone(newState[collection])) {
          return state;
        }

        // if this is the first API_CORE_REQUEST,
        // collection probably doesn't exist yet
        if ('entity' in action.payload && action.payload.entity) {
          const schema = action.payload.entity as EntityKey;

          if (isNone(newState[collection]) && schema) {
            newState[collection] = createEmptyCollection(schema);
          }
        }

        return mergeWithCollection(newState, collection, {
          loading: false,
        });
      }

      if (
        action.type === API_CORE_FAILURE &&
        action.payload &&
        'collection' in action.payload &&
        action.payload.collection
      ) {
        const newState = Object.clone(state);
        const collection = action.payload.collection;

        if (isNone(newState[collection])) {
          return state;
        }

        // if this is the first API_CORE_REQUEST,
        // collection probably doesn't exist yet
        if ('entity' in action.payload && action.payload.entity) {
          const schema = action.payload.entity as EntityKey;

          if (isNone(newState[collection]) && schema) {
            newState[collection] = createEmptyCollection(schema);
          }
        }

        return mergeWithCollection(newState, collection, {
          loading: false,
        });
      }

      if (
        action.type === ENTITIES.USERS_LOADING &&
        action.payload &&
        'collection' in action.payload &&
        'loading' in action.payload
      ) {
        const newState = { ...state };
        const collection = newState?.[action.payload.collection] ?? null;
        if (!collection) return state;
        collection.loading = action.payload.loading;
        newState[action.payload.collection] = collection;
        return newState;
      }
    }
    return state;
  } else {
    return state;
  }
};
