import React, { ComponentPropsWithoutRef, forwardRef } from 'react';
import Icons from 'assets/icons/sprite.svg';
import styles from './index.module.scss';
import classNames from 'classnames';

interface IconProps extends ComponentPropsWithoutRef<'svg'> {
  title?: string;
  name: string;
  top?: number;
  left?: number;
  display?: 'block' | 'inline' | 'inline-block' | 'none';
  spin?: boolean;
}

const Icon = forwardRef<SVGSVGElement, IconProps>(function Icon(props, ref) {
  const {
    className,
    fill,
    stroke,
    height,
    width,
    title,
    style,
    name,
    top,
    left,
    display,
    spin,
    ...attr
  } = props;

  let color = fill;
  color ||= stroke;
  color ||= undefined; // fallback to css or svg sprite colors if css doesn't load

  let size = height;
  size ||= width;
  size ||= '1em'; // always supply a size in case css doesn't load

  let svgTitle = title;
  svgTitle ||= name;

  const classes = classNames(styles.icon, className, {
    [styles.spin]: spin,
  });
  const inlineStyles = {
    ...style,
    color,
    top,
    left,
    display,
  };

  return (
    <svg
      {...attr}
      ref={ref}
      role="img"
      style={inlineStyles}
      className={classes}
      fill={fill}
      width={size}
      height={size}
    >
      <title>{svgTitle}</title>
      <use xlinkHref={`${Icons}#${name}`} />
    </svg>
  );
});

export default Icon;
