import type { Reducer } from 'redux';
import { ACTIVITY_LOG } from 'modules/common/constants';
import { ActivityReducer } from 'types/modules/activity-log';
import { isPayloadAction } from 'modules/common';
import { SORT } from 'utils/constants';

export const initialActivityState: ActivityReducer = {
  sortBy: {
    label: 'Descending',
    key: 'sort/created/DESCENDING',
    query: { ordering: '-created' },
  },
  pagination: {
    count: 0,
    numberOfRows: 50,
    rowCount: 0,
    startIndex: 0,
    total: 0,
  },
};

export const activityReducer: Reducer<ActivityReducer, any> = (
  state = initialActivityState,
  action
) => {
  if (!isPayloadAction(action)) {
    return state;
  }

  switch (action.type) {
    case ACTIVITY_LOG.UPDATE.PAGINATION: {
      return {
        ...state,
        pagination: {
          ...state.pagination,
          ...action.payload,
        },
      };
    }
    case ACTIVITY_LOG.SORT: {
      return {
        ...state,
        sortBy: {
          ...state.sortBy,
          ...action.payload,
        },
      };
    }
    default:
      return state;
  }
};
